.tarot-container {
    background-image: url('./assets/background.webp');
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 20px;
    box-sizing: border-box;
    overflow: auto;
  }
  
  .tarot-header {
    margin-bottom: 10px;
  }
  
  .logo {
    width: 150px;
    height: auto;
  }
  
  .tarot-header h1 {
    font-size: 2em;
    margin: 0;
    font-weight: bold;
  }
  
  .tarot-header h2 {
    font-size: 1.2em;
    color: #d4b6ff;
    margin: 0;
    font-weight: bold; /* Deixar o texto em negrito */
  }
  
  .tarot-content {
    max-width: 600px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
  }
  
  .sobre-mim {
    margin: 20px auto;
    max-width: 600px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
  }
  
  .sobre-mim h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .minha-foto {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .sobre-mim p {
    font-size: 1em;
    color: #ffffff;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .sobre-mim h3 {
    font-size: 1.2em;
    margin-top: 20px;
    color: #ff7c02;
  }
  
  /* Remover o CSS dos botões */
  