.comments-container {
  margin: 20px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-width: 100%;
  overflow: hidden;
}

.comments-container h3 {
  color: #000;
}

.comment-wrapper {
  display: flex;
  margin-bottom: 15px;
  max-width: 100%;
  flex-wrap: nowrap;
}

.comment-user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
}

.comment {
  flex: 1;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 10px;
  max-width: 100%;
  overflow: hidden;
}

.comment-header {
  display: flex;
  align-items: center;
}

.comment-username {
  color: #333;
  font-weight: bold;
  margin-right: 10px;
  font-size: 0.9em;
}

.comment-content {
  text-align: left;
  margin-top: 5px;
}

.comment-content p {
  margin: 5px 0;
  color: #000;
  font-size: 0.9em;
}

.comment-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.comment-actions {
  display: flex;
  align-items: center;
}

.like-button,
.reply-button {
  background: none;
  border: none;
  color: #4267b2;
  cursor: pointer;
  margin-right: 5px;
}

.like-button.liked,
.like-button:disabled {
  color: #888;
  cursor: default;
}

.like-button:hover:not(.liked):not(:disabled),
.reply-button:hover {
  text-decoration: underline;
}

.timestamp {
  color: #888;
  font-size: 0.9em;
  margin-left: 5px; /* Aproximar do botão de responder */
  white-space: nowrap; /* Manter o tempo em uma linha */
}

.likes {
  display: flex;
  align-items: center;
}

.like-heart-icons {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 3px 8px;
  margin-left: 5px; /* Ajustado para mover mais para a esquerda */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Adicionado para garantir que a seção respeite os limites */
}

.likes .icon {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}

.likes span {
  color: #666;
}

.login-required {
  margin-top: 20px;
  color: #888;
  font-size: 0.9em;
  text-align: center;
}

.consultation-call-to-action {
  text-align: center;
  margin-top: 10px; /* Reduzido para aproximar os textos */
}

.consultation-title {
  font-size: 1.5em; /* Aumentado para destacar o título */
  font-weight: bold;
  color: orange;
  margin-bottom: 0; /* Removido o espaço abaixo do título */
}

.consultation-subtitle {
  font-size: 1.2em; /* Aumentado o tamanho do subtítulo */
  color: gray; /* Alterado para cinza */
  font-weight: bold;
  margin-top: 0; /* Removido o espaço acima do subtítulo */
}

/* Media query para telas menores */
@media (max-width: 768px) {
  .comment-wrapper {
    flex-direction: row;
    align-items: flex-start;
  }

  .comment-header {
    flex-direction: row;
    align-items: center;
    max-width: 100%;
  }

  .comment-username {
    margin-left: 10px;
  }

  .comment-footer {
    margin-top: 5px;
  }

  .like-heart-icons {
    margin-top: 5px;
    margin-left: 5px; /* Ajustado para telas menores também */
  }

  .like-button,
  .reply-button {
    margin-right: 3px;
  }
}
