.purchase-container {
  text-align: center;
  padding: 50px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 50px auto;
}

.thank-you-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.purchase-container h1 {
  font-size: 1.5em; /* Diminui o tamanho do texto */
  margin-bottom: 20px;
  color: #343a40;
}

.purchase-container p {
  font-size: 1.2em;
  color: #6c757d;
  margin-bottom: 30px;
}

.purchase-container .pulse-button {
  padding: 15px 30px;
  font-size: 1.2em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  animation: pulse 1.5s infinite;
}

.purchase-container .pulse-button:hover {
  background-color: #0056b3;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
